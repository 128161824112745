import React, { Component } from "react";
import { Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { loadStripe } from "@stripe/stripe-js";
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Donate from "./components/donate.js";
import DonationOptions from "./components/donation-options.js";
import LearnMoreButton from "./components/learn-more.js";
import SocialComponent from "./components/social-component.js";
import Checkout from "./pages/page-donate.js";
import { Link, withRouter } from "react-router-dom";
import heroImage from "./images/hero_image.png";
import bottomImage from "./images/bottom_image.png";
import "./App.scss";
const data = require("./data/data.json");

class App extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="row">
          <div className="double-column">
            <div className="column">
              <div className="section-main">
                <div className="section-main-title">
                  <img src={heroImage}></img>
                  <div style={{ minWidth: "300px" }}></div>
                  <div className="topTitle">
                    <h1>{data.title}</h1>
                  </div>
                </div>

                <div className="section-main-description">
                <p>The Eduro Employee Fund comes with a heartfelt request that underscores the essence of our close-knit community. Today, we stand united in helping our colleague and friend, Marissa Honnette, and her twin 7-year-old daughters, Bea and Danny, as they navigate through an unimaginable loss.</p>

                <p>On August 6th, tragedy struck when Wayne Pipes, a beloved husband, father, and dedicated driver for Suburban Propane in Oakhurst, lost his life in a devasting car accident. Wayne was fulfilling his responsibilities when fate took an unexpected turn, leaving his family shattered.</p>

                <p>In times like these, our solidarity and compassion define who we are as a family. The Eudro Employee Fund is a shining example of our commitment to supporting one another, and times like this highlight the need to continue to support our colleagues and friends like Marissa.</p>

                <p>Every donation, no matter the size, makes a significant impact. The contributions collected will go directly to the Eduro Employee Fund, which will help Bea, Danny, and their mother’s lives during this difficult time. By standing together as a community we can alleviate some of the financial strain and provide a glimmer of hope for the future of this grieving family.</p>

                <p><b>Here's how you can make a difference:</b></p>

                <p><b>Donate:</b> Make a contribution in any amount you feel comfortable with. Remember, every bit helps.</p>

                <p><b>Spread the Word:</b> Share this initiative with your colleagues, friends, and family by sharing this link amplifying our collective efforts to assist those in need, like Marissa during this trying time.</p>

                <p><b>Send Your Well Wishes:</b> Alongside your donation, we encourage you to send your messages of support and encouragement to Marissa. Your words of comfort can provide much-needed strength.</p>

                <p>We have launched this Eduro Employee fundraising campaign with the aim of reaching $10,000 over the next two weeks. All donations will be tax-deductible.</p>

                <p>Let's rally together and remind Marissa that she is not alone - we stand by her side, ready to lend our support and assistance.</p>

                <p>Thank you for embodying the spirit of compassion that makes our Eduro community truly special. Together, we can make a meaningful impact and continue to showcase the power of unity in times of adversity.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="section-donate">
              <Donate />
              <DonationOptions stripeAction={this.showModal} />
              <SocialComponent />
              <div class="section-donate-disclaimer">
                *While we work to ensure accuracy, the stats indicated on this
                page may not be an exact reflection of actual activity.
                <br />
                ** If you would like to make a large donation with a different
                payment method, please reach out to Givinga at
                support@givinga.com
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
